import React from "react";
import { Col } from "react-bootstrap";

import ButtonText from "../components/ButtonText";
import Logo from "../images/icon-transparent.svg";

const ContentBox = ({
  variant,
  category,
  title,
  text,
  button,
  displayLogo,
}) => (
  <div className={`content-box -${variant}`}>
    {category && <div className="content-box__category">{category}</div>}
    <div className="content-box__headline">
      {displayLogo && <Logo className="content-box__logo" />}
      <h3
        className="content-box__header"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
    <div
      className="content-box__description"
      dangerouslySetInnerHTML={{ __html: text }}
    />
    {button?.link?.url && (
      <Col xs="24">
        <ButtonText variant={`small,${button.variant}`} {...button.link}>
            {button.link.title}
          </ButtonText>
      </Col>
    )}
  </div>
);

export default ContentBox;
